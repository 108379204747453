<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items
      v-if="userData"
      :items="resolveMenu(userData.userRoleId)"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/named
import { resolveMenu } from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  data() {
    return {
      userData: useJwt.getUser(),
    }
  },
  setup() {
    return {
      resolveMenu,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
